import React from 'react'
import '../App.css'

export default function Loding() {
    return (
        <div className='body-loder'>
            <div className="loader">
                <div className="inner"></div>
            </div>
        </div>
    )
}
