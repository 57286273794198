import React from "react";
import "../App.css";


export default function Song(props) {
  const data = props.data;
  const url = data.url

  // Using substring
  

  return (
    <div className="song-container">
      {props.isShow && (
        <div className="song-box">
          <img src={data.img_url} alt="" />
          <p className="song-name">{data.name}</p>
          <div className="song-info">
            <p className="song-artist">{data.artist}</p>
            <p className=" song-album">{data.albumName}</p>
          </div>

          <button onClick={() => window.open(`https://lkuminecraft.com/api/downlode?url=${url}`)}>
            Download
          </button>
        </div>
      )}
    </div>
  );
}
