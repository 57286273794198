import React from 'react'
import './pol.css'

export default function Policies() {
    return (
        <div className='body'>
            <div className="back">
                <p className="back-arrow"
                onClick={()=>{
                    window.location.href ="/"
                }}
                >
                    🡨 back
                </p>
            </div>


            <div class="container">
                <h1>Usage Policy:</h1>
                <p>The converter provided on this platform is intended solely for educational purposes. Users are strictly prohibited from engaging in any activity that violates the terms of service of Spotify or any other music streaming platform. Any unauthorized use of the converter for downloading copyrighted material is strictly prohibited and may result in legal action.</p>

                <h1>Copyright Notice:</h1>
                <p>All content provided by the converter, including but not limited to converted files and associated metadata, is subject to copyright protection. Users are solely responsible for ensuring that their use of the converter complies with applicable copyright laws and regulations. We do not condone or endorse the unauthorized downloading or distribution of copyrighted material.</p>

                <h1>API Usage:</h1>
                <p>To access the Spotify API and integrate it with the converter, users must adhere to the terms and conditions outlined by Spotify in their Developer Terms of Service. The API may only be used for legitimate purposes and in compliance with all applicable laws and regulations. Users are responsible for obtaining any necessary permissions or licenses required for their use of the Spotify API.</p>

                <h1>Converter Functionality:</h1>
                <p>The converter operates by accessing publicly available content on Spotify and converting it into a compatible format for educational purposes only. It does not circumvent any digital rights management (DRM) protections or engage in any illegal activities. Users should use the converter responsibly and refrain from infringing upon the rights of copyright holders.</p>

                <h1>Disclaimer:</h1>
                <p>While we strive to provide accurate and reliable services, we make no guarantees regarding the availability, accuracy, or functionality of the converter. Users access and use the converter at their own risk. We disclaim any liability for damages arising from the use of the converter or any reliance placed on its output.</p>

                <h1>Acceptance of Terms:</h1>
                <p>By using the converter, users acknowledge and agree to abide by these policies and copyright terms. Violation of these terms may result in the termination of access to the converter and potential legal consequences.</p>

                <p class="disclaimer">Feel free to customize and adjust the language to better fit your platform's specific use case and policies. It's crucial to ensure that users understand the limitations and responsibilities associated with the usage of the converter.</p>
            </div>
        </div>
    )
}
