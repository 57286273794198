// f:/spotify_song_downloder/frontend/src/App.js
import "./App.css";
import { useState } from "react";
import axios from "axios";
import Songs from "./componets/Songs.js";
import Loding from "./componets/Loding.jsx";
import { Helmet } from "react-helmet";

function Home() {
  const [isLodeing, setIsLodeing] = useState(false);
  const [isShowSong, setIsShowSong] = useState(false);
  const [songData, setSongData] = useState({});

  const fecthSong = async (url) => {
    setIsLodeing(true);
    const response = await axios.get(
      `https://lkuminecraft.com/api/track?url=${url}&key=free`
    );
    if (response.status === 200) {
      setSongData(response.data);

      setIsShowSong(true);
      setIsLodeing(false);
    } else {
      alert("something went wrong!");
    }
  };

  return (
    <div className="App">
      <Helmet>
        
        {/* <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Learn how to download Spotify songs using YouTube with our comprehensive guide. Convert and download tracks for offline listening anytime, anywhere!"
        />
        <meta
          name="keywords"
          content="download Spotify songs, Spotify to YouTube, convert Spotify to YouTube, download music offline, Spotify offline listening, YouTube music conversion"
        />
        <title>
          Download Spotify Songs Using YouTube: Ultimate Guide for Offline
          Listening
        </title>

        {/* Open Graph / Facebook meta tags */}
        <meta
          property="og:title"
          content="Download Spotify Songs Using YouTube: Ultimate Guide for Offline Listening"
        />
        <meta
          property="og:description"
          content="Learn how to download Spotify songs using YouTube with our comprehensive guide. Convert and download tracks for offline listening anytime, anywhere!"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://downloder.000xs.xyz/download-spotify-songs-using-youtube"
        />
        <meta
          property="og:image"
          content="https://downloder.000xs.xyz/images/spotify-youtube.jpg"
        />

        {/* Twitter meta tags */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://downloder.000xs.xyz/download-spotify-songs-using-youtube"
        />
        <meta
          property="twitter:title"
          content="Download Spotify Songs Using YouTube: Ultimate Guide for Offline Listening"
        />
        <meta
          property="twitter:description"
          content="Learn how to download Spotify songs using YouTube with our comprehensive guide. Convert and download tracks for offline listening anytime, anywhere!"
        />
        <meta
          property="twitter:image"
          content="https://downloder.000xs.xyz/images/spotify-youtube.jpg"
        />

        {/* Canonical link */}
        <link
          rel="canonical"
          href="https://downloder.000xs.xyz/download-spotify-songs-using-youtube"
        />
        {/* <!-- Google tag (gtag.js) --> */}
        */}
      </Helmet>
      
      <script type="application/ld+json">
        {`
          {
            "@context": "http://schema.org",
            "@type": "Article",
            "headline": "Download Spotify Songs Using YouTube: Ultimate Guide for Offline Listening",
            "description": "Learn how to download Spotify songs using YouTube with our comprehensive guide. Convert and download tracks for offline listening anytime, anywhere!",
            "author": {
              "@type": "Person",
              "name": "dev,000xs"
            },
            "datePublished": "2024-06-15",
            "image": "https://downloder.000xs.xyz/images/spotify-youtube.jpg",
            "publisher": {
              "@type": "Organization",
              "name": "000x",
              "logo": {
                "@type": "ImageObject",
                "url": "https://downloder.000xs.xyz/logo.jpg"
              }
            }
          }
        `}
      </script>
      {isLodeing && <Loding />}

      <header className="App-header">
        <div className="back">
          <p
            className="back-arrow"
            onClick={() => {
              window.location.href = "/Policy";
            }}
          >
            🡨 Policy
          </p>
        </div>
        {isShowSong === false ? (
          <div className="song-container ">
            <div className="link box">
              <h1>Download Spotify Songs Using YouTube: The Ultimate Guide</h1>
              <div className="input">
                <input
                  type="text"
                  id="url"
                  placeholder="Paste your Spotify link here!"
                />
                <button
                  onClick={() =>
                    fecthSong(document.getElementById("url").value)
                  }
                >
                  Search
                </button>
              </div>
              <div className="guide">
                Short Guide:
                <ol>
                  <li>
                    Open Spotify and find your desired song/playlist/album.
                  </li>
                  <li>
                    Desktop: Right-click the title/name/cover and choose "Copy
                    Link to..." (Song/Playlist/Album).
                  </li>
                  <li>
                    Mobile: Tap the three dots (...) next to the
                    title/name/cover, then "Share"   ="Copy Link".
                  </li>
                  <li>
                    The link is now copied! Paste it wherever you need to
                    downlode.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        ) : (
          <div className="sec-2">
            <p className="back-arrow" onClick={() => setIsShowSong(false)}>
              🡨 back
            </p>
            <Songs isShow={isShowSong} data={songData} />
          </div>
        )}
      </header>
    </div>
  );
}

export default Home;
