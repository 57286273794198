import React, { useEffect } from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home';
import Policies from './Policies';
 

// Initialize Google Analytics
import ReactGA from 'react-ga';
const TRACKING_ID = "G-7WZNZH5MFZ"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const App = () => {
  // Track page views using useLocation hook
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  useEffect(() => {

  }, []);

  return (
    <BrowserRouter>
      <Routes>

        <Route index element={<Home />} />
        <Route path="*" element={<Policies />} />

      </Routes>
    </BrowserRouter>
  );
};

export default App;
